<template>
  <div class="space-y-6">
    <div class="px-6 pb-3 border-b">
      <div class="text-xl font-semibold pt-5 flex items-center space-x-4">
        <div class="flex-grow">Tasters</div>
      </div>

      <div class="text-gray-600 mt-1">
        Generate a report to show the which taster are the most popular
      </div>
    </div>
    <div class="flex p-6 w-full flex-grow">
      <div class="flex flex-col space-y-4 flex-grow">
        <div class="flex space-x-4 items-center">
          <div class="flex flex-grow">
            <div class="flex-grow">
              <div class="text-xl font-bold">{{ core.userCount }}</div>
              <div class="text-sm text-gray-500 font-mono">Total</div>
            </div>
            <div class="flex-grow">
              <div class="text-xl font-bold">{{ core.convertedCount }}</div>
              <div class="text-sm text-gray-500 font-mono">Converted</div>
            </div>

            <div class="flex-grow">
              <div class="text-xl font-bold">
                {{
                  parseFloat(
                    ((core.convertedCount / core.userCount) * 100).toFixed(1)
                  )
                }}%
              </div>
              <div class="text-sm text-gray-500 font-mono">Conversion Rate</div>
            </div>
            <div class="flex-grow">
              <div class="text-xl font-bold">
                {{ core.allUsersConverted ? 'Yes' : 'No' }}
              </div>
              <div class="text-sm text-gray-500 font-mono">
                All users converted
              </div>
            </div>
          </div>
          <div class="w-1/2 flex justify-end items-center space-x-4">
            <div class="font-mono text-gray-500 text-sm">Choose a date</div>
            <date-range-picker
              ref="picker"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
              :minDate="minDate"
              :maxDate="maxDate"
              v-model="dateRange"
              @update="getStockData"
              :autoApply="true"
              opens="left"
            >
              <template v-slot:input="picker">
                {{ picker.startDate | formatDate('DD MMMM, yyyy') }} -
                {{ picker.endDate | formatDate('DD MMMM, yyyy') }}
              </template>
            </date-range-picker>

            <loading-button
              class="hidden"
              secondary
              is-submit
              label="Generate Report"
              :is-loading="false"
              :is-enabled="true"
            />
          </div>
        </div>
        <div class="divide-y border w-full">
          <div>
            <div
              class="flex space-x-2 items-stretch divide-x text-sm text-gray-500 font-mono"
            >
              <div class="flex-grow py-1 px-2 flex flex-col items-start"></div>
              <div
                class="w-48 text-right py-4 px-2 flex items-center justify-end"
              >
                All
              </div>
              <div
                class="w-48 text-right py-1 px-2 flex items-center justify-end"
              >
                Converted
              </div>
              <div
                class="w-48 text-right py-1 px-2 flex items-center justify-end"
              >
                Conversion Rate
              </div>
            </div>
          </div>
          <div
            v-for="taster in stats"
            :key="taster.sku"
            class="flex space-x-2 items-stretch divide-x"
          >
            <div class="flex-grow py-2 px-4 flex flex-col items-start">
              <div>{{ taster.name }} <span v-if="taster.dry">(Dry)</span></div>
              <div class="text-xs mt-1 font-mono text-gray-500">
                {{ taster.sku }}
              </div>
            </div>
            <div
              class="w-48 text-right py-1 px-2 flex items-center justify-end"
            >
              {{ taster.all }}
            </div>
            <div
              class="w-48 text-right py-1 px-2 flex items-center justify-end"
            >
              {{ taster.converted }}
            </div>
            <div
              class="w-48 text-right py-1 px-2 flex items-center justify-end"
            >
              {{ taster.conversionRate }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { snakeCase } from 'lodash/fp';
import LoadingButton from '@/components/common/ui/LoadingButton.vue';
import { subMonths, format } from 'date-fns';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  components: { LoadingButton, DateRangePicker },
  name: 'ReportingTasters',

  data() {
    return {
      stats: [],
      core: null,
      minDate: new Date('01-04-2024'),
      maxDate: new Date(),
      dateRange: {
        startDate: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd')
      }
    };
  },
  computed: {
    ...mapGetters({
      products: 'getTasterProducts'
    })
  },
  methods: {
    ...mapActions({
      getData: 'getTasterStats'
    }),
    getStockData() {
      this.getData({
        endDate: this.dateRange.endDate,
        startDate: this.dateRange.startDate
      }).then(resp => {
        this.core = resp.data.content[0];
        this.stats = this.formatData(resp.data.content[0]);
      });
    },
    formatData(data) {
      console.log(data);
      // merge the allSkus keys and the convertedSkus keys where the keys match

      const merged = Object.keys(data.allSkus).map(key => {
        const sku = snakeCase(key).toUpperCase();
        const product = this.products.find(p => p.sku === sku);
        const all = data.allSkus[key] || 0;
        const converted = data.convertedSkus[key] || 0;
        return {
          sku,
          name: product?.name
            ? `${product.name} (${product.description})`
            : 'Chicken + Fish Bundle',
          dry: !sku.includes('_NDY_'),
          all: all,
          converted: converted,
          conversionRate: parseFloat(((converted / all) * 100).toFixed(1)),
          // scale the conversion rate so its normalised to the user count
          scalledConversionRate: parseFloat(
            (
              (converted / all) *
              (this.core.convertedCount / this.core.userCount) *
              100
            ).toFixed(1)
          )
        };
      });

      // order by highest conversion rate
      merged.sort((a, b) => b.conversionRate - a.conversionRate);
      console.log('m', merged);
      return merged;
    },

    generateReport() {
      this.getStockData();
    }
  },
  mounted() {
    this.getStockData();
  }
};
</script>

<style></style>
