<template>
  <div class="space-y-6">
    <div class="px-6 pb-3 border-b">
      <div class="text-xl font-semibold pt-5 flex items-center space-x-4">
        <div class="flex-grow">Monthly Stock Counts Report</div>
      </div>
      <div class="text-gray-600 mt-1">
        Generate a report to show the levels of stock for a given date of the
        month
      </div>

      <div class="text-gray-600 flex space-x-2 items-center my-4">
        <div class="flex-none">Show me the stock for the</div>
        <div class="relative flex items-center flex-none">
          <select v-model="date" class="pr-12">
            <option v-for="index in 28" :key="`dom-${index}`" :value="index">
              {{ index | ordinal }}
            </option>
          </select>
          <div class="absolute right-4 text-sm">
            <font-awesome-icon :icon="['fal', 'chevron-down']" />
          </div>
        </div>

        <div class="flex-none">of each month over the last</div>
        <div class="relative flex-none flex items-center">
          <select v-model="period" class="pr-12">
            <option :value="1">1</option>
            <option :value="3">3</option>
            <option :value="6">6</option>
          </select>
          <div class="absolute right-4 text-sm">
            <font-awesome-icon :icon="['fal', 'chevron-down']" />
          </div>
        </div>
        <div class="flex-none">month period</div>
        <form @submit.prevent="generateReport" class="flex-none">
          <loading-button
            secondary
            is-submit
            label="Generate Report"
            :is-loading="loading"
            :is-enabled="true"
          />
        </form>
      </div>
    </div>
    <div
      v-for="(key, value) in formattedStock"
      :key="value"
      class="border-b pb-6 px-6"
    >
      <div class="grid" v-for="(item, i) in key" :key="item.date">
        <div
          v-if="i === 0"
          class="grid grid-cols-8 font-mono"
          :class="{ 'mb-2': i === 0 }"
        >
          <div class="font-semibold font-mono">{{ getGroupName(value) }}</div>
          <div></div>
          <div
            v-for="date in dates"
            class="text-center text-gray-400 text-sm uppercase"
            :key="date.label"
          >
            {{ date.label }}
          </div>
        </div>
        <div
          class="font-mono grid grid-cols-8 hover:bg-gray-100 py-1 items-center"
        >
          <div class="flex flex-none">
            {{ item.name }} <span v-if="item.isKitten">(Kitten)</span>
          </div>
          <div class="text-gray-400 text-sm italic">{{ item.sku }}</div>

          <div
            v-for="(key, value) in item.inStock"
            class="text-center"
            :key="value"
          >
            <transition name="fade" mode="out-in">
              <div :key="value">{{ formatValue(key, item.type) }}</div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { groupBy, orderBy } from 'lodash/fp';
import LoadingButton from '@/components/common/ui/LoadingButton.vue';
import { subMonths, startOfMonth, addDays, format } from 'date-fns';
export default {
  components: { LoadingButton },
  name: 'ReportingMonthlyStock',

  data() {
    return {
      stockData: [],
      formattedStock: [],
      period: '3',
      date: '2',
      mapper: [
        { key: 'wet_tray', label: 'Trays' },
        { key: 'wet_pouch', label: 'Pouches' },
        { key: 'wet_tasty', label: 'Tasty Slices' },
        { key: 'wet_chunk', label: 'Tasty Chunks' },
        { key: 'dry', label: 'Dry Food' },
        { key: 'wet_can', label: 'Deluxe Cans' },
        { key: 'wet_smooth', label: 'Smooth' }
      ],
      loading: false,
      dates: []
    };
  },
  computed: {
    ...mapGetters({
      dryIncrement: 'dryIncrementGetter'
    })
  },
  methods: {
    getDates() {
      const today = subMonths(startOfMonth(new Date()), 0);
      const dates = [];
      const add = this.date - 1;
      for (let i = 0; i < this.period; i++) {
        const date = addDays(subMonths(today, i), add);
        const formatted = {
          date: format(date, 'yyy-MM-dd'),
          label: format(date, 'MMM yy')
        };
        dates.push(formatted);
      }

      return orderBy(['date'], ['asc'], dates);
    },
    ...mapActions({
      getData: 'getAnalyticsBulk'
    }),
    getStockData() {
      this.dates = this.getDates();
      this.getData({
        dates: this.dates.map(item => item.date),
        attribute: 'stockLevels'
      }).then(resp => {
        resp.forEach(item => {
          item.data = item.data.filter(item => {
            return item.product.foodcategory !== 'misc';
          });
        });

        this.stockData = resp;
        this.formatStockData();
        this.loading = false;
      });
    },
    generateReport() {
      this.loading = true;

      this.getStockData();
    },
    formatStock(stock) {
      return stock < 0 ? 0 : stock;
    },
    formatStockData() {
      const products = this.stockData.map(item => {
        return item.data.map(product => {
          return {
            sku: product.product.sku,
            name: product.product.name,
            inStock: this.formatStock(product.inStock),
            date: item.date,
            type: product.product.foodtype,
            isKitten: product.product.foodcategory === 'kitten'
          };
        });
      });

      //create an array to merge and groupBy the products array and have inStock values from each date
      const merged = products.reduce((acc, val) => {
        val.forEach(item => {
          const found = acc.find(product => product.sku === item.sku);

          if (found) {
            console.log(item.date, item.sku, item.inStock);
            found.inStock[item.date] = item.inStock;
          } else {
            console.log(item.date, item.sku, item.inStock);
            acc.push({
              sku: item.sku,
              name: item.name,
              inStock: { [item.date]: item.inStock },
              type: item.type,
              isKitten: item.isKitten
            });
          }
        });
        return orderBy(['isKitten', 'name'], ['asc', 'asc'], acc);
      }, []);

      // group merged array by type

      this.formattedStock = groupBy('type', merged);
    },
    getGroupName(val) {
      const found = this.mapper.find(item => item.key === val);
      return found ? found.label : '-';
    },
    formatValue(val, type) {
      if (type === 'dry') {
        return (val / this.dryIncrement).toLocaleString();
      }
      return val.toLocaleString();
    }
  },
  mounted() {}
};
</script>

<style></style>
